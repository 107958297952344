<template>
  <b-modal
    id="modal-void-ticket"
    :title="isExternalTickets ? $t('reservation.voidTicket.titleExternal') : $t('reservation.voidTicket.title')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    size="lg"
    body-class="p-50"
    no-close-on-backdrop
    @show="handleModalOpen"
  >
    <div v-if="disabledVoidForQH">
      Không còn hành trình để thực hiện void.
    </div>

    <div v-else>
      <b-card body-class="p-50">
        <div class="text-heading-4">
          {{ $t('reservation.voidTicket.selectTicketToVoid') }}:
        </div>

        <b-form-group>
          <template #label>
            <b-form-checkbox
              v-if="!isEmpty(ticketList.filter(tk => tk.voidable))"
              v-model="allSelected"
              aria-describedby="etickets"
              aria-controls="etickets"
              class="py-0 pt-50"
              :disabled="isVoidAllEticket"
              @change="toggleAll"
            >
              <div
                class="text-body-2 font-weight-bold text-dark"
                style="padding-bottom: 6px"
              >
                {{ allSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
              </div>
            </b-form-checkbox>
          </template>

          <template #default="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="indexSelectedTicketToVoid"
              :aria-describedby="ariaDescribedby"
              name="etickets"
              stacked
            >
              <div
                v-for="(ticket, indexTicket) in ticketList"
                :key="indexTicket"
                class="d-flex-center justify-content-start py-0 pb-50 ml-50 ml-lg-75 w-100"
              >
                <b-form-checkbox
                  :value="indexTicket"
                  :disabled="!ticket.voidable || isVoidAllEticket"
                >
                  <div class="d-flex flex-wrap font-medium-1 fw-700">
                    <div>
                      <span class="text-warning">{{ ticket.number }}</span>
                      <b-badge
                        variant="info"
                        class="px-50 ml-50 rounded-lg fw-700"
                      >
                        {{ ticket.ticketType }}
                      </b-badge>
                      <span
                        v-if="ticket.status"
                        :class="`ml-25 ${['OK', 'OPEN', 'EMD'].includes(ticket.status) ? 'text-success' : 'text-warning'}`"
                      >
                        - {{ ticket.status }}
                      </span>
                    </div>

                    <div class="mx-50">
                      {{ ticket.passengerPaxId ? `(${ticket.passengerPaxId})` : '' }} {{ ticket.passengerName }}<br>
                    </div>

                    <div>
                      <code
                        v-if="['OK', 'CTRL','OPEN','EMD'].includes(ticket.status) && bookingData.source !== 'QH'"
                        class="ml-25 pt-25"
                      >
                        {{ $t('reservation.voidTicket.paymentTime') }}: {{ convertISODateTime(ticket.dateIss).date }}
                      </code>
                    </div>
                  </div>
                </b-form-checkbox>
              </div>
            </b-form-checkbox-group>
          </template>
        </b-form-group>
      </b-card>

      <b-card
        v-if="!!lockVoid || ['1G', 'QH', 'VN1A', 'VN1A_MT'].includes(bookingData.source)"
        no-body
      >
        <b-alert
          v-if="!!lockVoid"
          variant="danger"
          :show="!!lockVoid"
          class="p-50 m-0"
        >
          {{ lockVoid }} {{ $t('reservation.voidTicket.lockVoid') }}
        </b-alert>
        <b-alert
          v-if="['VN1A', 'VN1A_MT'].includes(bookingData.source)"
          show
          variant="warning"
          class="p-1 m-50"
        >
          <div class="text-danger fw-700">
            Lưu ý: <br>
            - {{
              isRoleF1
                ? 'Số vé hoàn (RFD) vui lòng kiểm tra kỹ trước khi VOID, user sẽ tự chịu trách nhiệm cho hành động này'
                : `Số vé hoàn (RFD) vui lòng liên hệ booker để VOID`
            }}
          </div>
        </b-alert>
        <b-alert
          v-if="['QH'].includes(bookingData.source)"
          variant="warning"
          show
          class="p-1 m-50 fw-700 text-airline"
        >
          <div>Bamboo Airways:</div>
          <div> - {{ $t('reservation.voidTicket.QH_void1') }}</div>
          <div> - {{ $t('reservation.voidTicket.QH_void2') }}</div>
        </b-alert>
        <b-alert
          v-if="['1G'].includes(bookingData.source)"
          variant="warning"
          show
          class="p-1 m-50 fw-700 text-airline"
        >
          <div>- {{ $t('reservation.voidTicket.VN_void1') }}</div>
        </b-alert>
      </b-card>
    </div>

    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill width-100"
        @click="close()"
      >
        {{ $t('reservation.close') }}
      </b-button>

      <b-button
        v-if="!disabledVoidForQH"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient mt-lg-25  border-0"
        :disabled="!indexSelectedTicketToVoid.length || lockVoid"
        pill
        @click="submitVoidTicket"
      >
        <span class="align-middle">{{ $t('reservation.voidTicket.title') }}</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BCard, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BButton, BAlert, BBadge,
} from 'bootstrap-vue'
import {
  ref, computed, watch, toRefs,
} from '@vue/composition-api'
import { cloneDeep, uniq, isEmpty } from 'lodash-es'

import store from '@/store'

import {
  convertISODateTime,
} from '@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal, BCard, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BButton, BAlert, BBadge,
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
    ticketList: {
      type: Array,
      default: () => [],
    },
    isExternalTickets: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    // Hiện tại QH void ticket là sẽ void toàn bộ ticket của 1 PNR
    // ========================================================
    const indexSelectedTicketToVoid = ref([])
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    // const indexSelectedTicketToVoidComputed = computed(() => indexSelectedTicketToVoid.value.sort())

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])

    const { voidTicket } = useReservationHandle()
    const { ticketList, bookingData, isExternalTickets } = toRefs(props)

    // check phải void hết các vé trong booking h5ay ko ? (QH và 1G_NDC)
    const isVoidAllEticket = computed(() => ['QH'].includes(bookingData.value.source) || (bookingData.value.source === '1G' && bookingData.value.extra.some(item => item.contentSource === 'NDC')))

    // nếu ko có itineraries hoặc itineraries.length = 0 thì ko cho void nhé (https://discord.com/channels/1054696448110903327/1237613926993363008/1237614743687270461)
    const disabledVoidForQH = computed(() => bookingData.value.source === 'QH' && !bookingData.value?.itineraries?.length)

    // ANCHOR SELECT ALL
    const allSelected = ref(false)
    function toggleAll(checked) {
      indexSelectedTicketToVoid.value = checked ? ticketList.value.filter(tk => tk.voidable).map(tk => ticketList.value.findIndex(it => it.number === tk.number)) : []
    }
    watch(() => indexSelectedTicketToVoid.value, selectLists => {
      allSelected.value = !isEmpty(selectLists) && (selectLists.length === ticketList.value.filter(tk => tk.voidable).length)
    })

    function handleModalOpen() {
      indexSelectedTicketToVoid.value = ticketList.value.filter(tk => tk.voidable).map(tk => ticketList.value.findIndex(it => it.number === tk.number))
    }

    const lockVoid = computed(() => {
      if (bookingData.value.airlines.includes('K6')) return 'K6'
      if (
        bookingData.value.airlines.includes('PR') && new Date(bookingData.value.itineraries[0][0].departure.at.toString()).getTime() - new Date().getTime() < 24 * 60 * 60 * 1000) {
        return 'PR'
      }
      return false
    })

    function submitVoidTicket() {
      this.$bvModal.show('modal-api-loading')
      const ticketSelectSorted = cloneDeep(indexSelectedTicketToVoid.value).sort()
      const payload = {
        contact: bookingData.value.paxContact.id,
        source: bookingData.value.source,
        agencyCode: bookingData.value.agency,
        paxCode: bookingData.value.paxContact.code,
        pnrNumber: bookingData.value.bookingCode,
        ticketNumbers: uniq(ticketList.value.filter((_, ticketIndex) => ticketSelectSorted.includes(ticketIndex)).map(ticket => ticket.number)),
      }

      voidTicket(payload)
        .then(() => {
          // this.$bvModal.show('modal-void-ticket-cancel-alert')
        })
        .finally(() => {
          this.$bvModal.hide('modal-void-ticket')
          this.$bvModal.hide('modal-api-loading')
        })
    }

    return {
      submitVoidTicket,
      convertISODateTime,
      indexSelectedTicketToVoid,
      allSelected,
      toggleAll,
      lockVoid,
      isEmpty,
      handleModalOpen,
      isVoidAllEticket,
      disabledVoidForQH,
      isRoleF1,
      // checkFirstDateIssueForQHDifToday,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
